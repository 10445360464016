<template class="rules">
  <section>
    <hero-global title="Normas" subtitle="Normas, Códigos y Planes" breadcrumb="Normas, Códigos y Planes"
      img="/img/standard-code-conventions/standard-code-conventions_1.svg" />
  </section>
  <div class="box-content container">
    <ul class="menu-list container">
      <li v-for="(rule, key) in store.rules" :key="key" :value="key" :aria-expanded="this.currentShow == rule.slug">
        <ul class="list-item" v-if="Object.values(rule.childSlug).length > 0">
          <li @click="toggle(rule.slug)">
            <input type="checkbox" name="list" :id="rule.slug" />
            <label :for="rule.slug">
              <router-link :class="[slug == rule.slug ? 'router-link-active' : '']" :to="{
                name: 'normas-reguladoras',
                params: {
                  slug: rule.slug
                }
              }" :title="`Ver ${rule.name}`" :alt="`Ver ${rule.name}`">
                {{ rule.name }}
              </router-link>
            </label>
            <div class="inner-container" :show="this.currentShow == rule.slug">
              <span v-for="(child, key ) in Object.values(rule.childSlug)" :key="key">
                <router-link :class="[slug == rule.slug ? 'router-link-active' : '']" :to="{
                  name: 'normas-reguladoras',
                  params: {
                    slug: rule.slug,
                    subSlug: child.slug,
                  }
                }" :title="`Ver ${child.name}`" :alt="`Ver ${child.name}`">
                  {{ child.name }}
                </router-link>
              </span>
            </div>
          </li>
        </ul>
        <router-link v-else :class="[slug == rule.slug ? 'router-link-active' : '']" :to="{
          name: 'normas-reguladoras',
          params: {
            slug: rule.slug
          }
        }" :title="`Ver ${rule.name}`" :alt="`Ver ${rule.name}`">
          {{ rule.name }}
        </router-link>
      </li>

    </ul>
    <div class="content container">
      <template v-if="ruleSelected">
        <h2 class="title-decoration-bottom font-family-frank-ruhl text-left">{{ ruleSelected.name }}</h2>
        <EditorJs :object="ruleSelected.body" />
      </template>
      <template v-else>
        <h2 class="title-decoration-bottom font-family-frank-ruhl text-left">Actualmente no hay contenido</h2>
      </template>
    </div>
  </div>
  <section class="container p-2-rem">
    <carrousel2 :dataContent="$tm('translations.carrouselData.cermi')" />
  </section>
  <section class="container p-2-rem">
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import Carrousel2 from "@/components/Global/Carrousel2";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import { contentStore } from '@/stores/contents'
import EditorJs from "@/components/Global/editorJs.vue"

export default {
  name: "StandardCodeConventions",
  components: {
    OtherLinksInterest,
    Carrousel2,
    HeroGlobal,
    EditorJs
  },
  props: ['slug', 'subSlug'],
  setup() {
    const store = contentStore();
    store.loadRules();
    return {
      store
    }
  },
  data: () => ({
    currentShow: null,
  }),
  mounted() {

  },
  computed: {
    ruleSelected() {
      let rule = null
      if (this.slug && this.subSlug && this.store.rules[this.slug] && Object.values(this.store.rules[this.slug].childSlug).length > 0) {
        rule = this.store.rules[this.slug].childSlug[this.subSlug]
      }
      else if (this.slug && this.store.rules[this.slug] && Object.values(this.store.rules[this.slug].childSlug).length > 0) {
        rule = this.store.rules[this.slug]
      }
      else if (this.store.rules[this.slug]) {
        rule = this.store.rules[this.slug]
      }
      else {
        rule = null
      }
      return rule

    }

  },
  watch: {

  },
  methods: {
    toggle(id) {
      console.log(id)
      if (this.currentShow === id) {
        this.currentShow = null;
      } else {
        this.currentShow = id;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/general.scss";

.box-content {
  background: linear-gradient(90deg, rgba(139, 173, 220, 0.1490196078) 35%, #ffffff 35%);

  .menu-list {
    width: 35%;

    li {
      list-style: none;

      label {
        padding: 0;
        margin-bottom: 20px;

        &:before {

          bottom: 10px;

        }

        a {
          margin: 0;



        }
      }

      .inner-container {
        padding-left: 20px;

        a {
          background: none;
          font-weight: normal;

          &.router-link-exact-active {
            background: white;
            font-weight: 600;
          }
        }
      }


    }

  }

  .content {
    width: 65%;
  }
}
</style>